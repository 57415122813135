import Auth from './Auth'
import AuthAdmin from './AuthAdmin'
import AuthDashboard from './AuthDashboard'
import AuthRegionalDirector from './AuthRegionalDirector'
import AuthHeadDepartment from './AuthHeadDepartment'
import AuthHeadResident from './AuthHeadResident'
export default {
  Auth,
  AuthAdmin,
  AuthDashboard,
  AuthRegionalDirector,
  AuthHeadDepartment,
  AuthHeadResident
}